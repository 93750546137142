<template>
	<div v-loading="Wholoading" class="pointerResults">
		<div style="padding: 15px;background: #fff;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;margin-bottom: 15px;color: #303133;">基本信息</div>
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
				<el-form-item label="积分规则" prop="GivingType">
					<div style="margin-bottom: 20px;">
						<el-radio v-model="ruleForm.GivingType" :label="0">
							<el-form-item label-width='0' :prop="(ruleForm.GivingType==0)?'OrderPay':'nocheck'" style="display: inline-block;">
								订单每成功支付
								<el-input style="width:150px;margin:0 10px" type="number" @blur="numberCheck($event)" v-model="ruleForm.OrderPay"></el-input>

								<!-- 	<input v-model="ruleForm.OrderPay" style="padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;margin:0 10px"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number"></input> -->
								笔,发放
							</el-form-item>

							<el-form-item label-width='0' :prop="(ruleForm.GivingType==0)?'OrderSend':'nocheck'" style="display: inline-block;">
								<!-- 	<input v-model="ruleForm.OrderSend" style="padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;margin:0 10px"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number"></input> -->
								<el-input style="width:150px;margin:0 10px" type="number" @blur="numberCheck($event)" v-model="ruleForm.OrderSend"></el-input>
								积分
							</el-form-item>
						</el-radio>

					</div>
					<div>
						<el-radio v-model="ruleForm.GivingType" :label="1">
							<el-form-item label-width='0' :prop="(ruleForm.GivingType==1)?'ProductPay':'nocheck'" style="display: inline-block;">
								商品每消费
								<!-- 	<input v-model="ruleForm.ProductPay" style="padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;margin:0 10px"
								 @keydown="handleInput2" type="number"> -->
								<el-input style="width:150px;margin:0 10px" type="number" @blur="handleInputnumber($event)" v-model="ruleForm.ProductPay"></el-input>
								</input>元,发放
							</el-form-item>

							<el-form-item label-width='0' :prop="(ruleForm.GivingType==1)?'ProductSend':'nocheck'" style="display: inline-block;">
								<!-- <input v-model="ruleForm.ProductSend" style="padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;margin:0 10px"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number"></input> -->
								<el-input style="width:150px;margin:0 10px" type="number" @blur="numberCheck($event)" v-model="ruleForm.ProductSend"></el-input>
								积分
							</el-form-item>

						</el-radio>
					</div>
				</el-form-item>
				<el-form-item v-if="ruleForm.GivingType == 1" label="适用商品" prop="IsAllProduct">
					<div>
						<el-radio v-model="ruleForm.IsAllProduct" :label="1">全部商品参加</el-radio>
					</div>
					<div>
						<el-radio v-model="ruleForm.IsAllProduct" :label="0">
							指定商品参加
						</el-radio>
						<span v-if="ruleForm.IsAllProduct == 0" style="color: #409EFF;cursor: pointer;" @click="handleSelectProDialog">选择商品</span>
					</div>
				</el-form-item>
				<el-form-item>
					<!-- //商品列表 -->
					<div style="background: #fff;" v-if="showFinaproList.length&&ruleForm.IsAllProduct==0&&ruleForm.GivingType==1">
						<el-table border :data="showFinaproList" style="width: 100%">
							<el-table-column label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content" style="display:flex;">
										<img :src='scope.row.ImgUrlComplete' style="width: 80px;height: 80px;"></img>
										<div style="width: 100%;">
											<div class="nameStyle" style="width: 100%;margin-left: 3px;">

												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

											</div>
											<div style="color:#E51C23;">{{scope.row.Remark}}</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="ProductPrice" label="价格"></el-table-column>
							<el-table-column prop="Stock" label="库存"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row)" type="text" size="small">删-除</el-button>
								</template>
							</el-table-column>
						</el-table>

						<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
						 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
						 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
						</el-pagination>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<!-- //选择商品弹窗 -->
		<!-- dialog -->
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="70%" class="dialog">
			<select-produce api="pointconfigjoinproductlist" :couponData="couponData" :params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId','IsChoosable','Id']"
			 :selectedData="ruleForm.ActivityCouponProductList" @getSelectList="getSelectList" :ishowRemark="ishowRemark"
			 :visible.sync="selectProDialog" v-if="selectProDialog" :isShowCheck='false'></select-produce>
		</el-dialog>

		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 20px;display: flex;justify-content: center;">
			<el-button style="width: 150px;" @click="BackTolist">取消</el-button>
			<el-button style="width: 150px;margin-left: 40px;" type="primary" @click="SaveScore('ruleForm')" :loading="sureLoading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import selectProduce from '@/components/SelectMulProduce'
	import {
		EditScoreconfig,
		getScoreconfigInfo
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			selectProduce
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			return {
				ishowRemark: false,
				showFinaproList: [],
				Wholoading: false,
				ConfigId: 0,
				sureLoading: false,
				couponData: {
					id: ''
				},
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableProList: [],
				selectProDialog: false,
				ruleForm: {
					GivingType: 0,
					OrderPay: '',
					OrderSend: '',
					ProductPay: '',
					ProductSend: '',
					IsAllProduct: 1,
					ActivityCouponProductList: [],
				},
				rules: {
					GivingType: [{
						required: true,
						message: '请选择积分规则',
						trigger: 'change'
					}, ],
					IsAllProduct: [{
						required: true,
						message: '请选择适用商品',
						trigger: 'change'
					}],
					OrderPay: [{
						required: true,
						message: '请设置订单笔数',
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					OrderSend: [{
						required: true,
						message: '请设置积分发放数量',
						trigger: 'blur'
					}],
					ProductPay: [{
						required: true,
						message: '请设置消费金额',
						trigger: 'blur'
					}],
					ProductSend: [{
						required: true,
						message: '请设置积分发放数量',
						trigger: 'blur'
					}],
				},
				// ProductIdList: [],
			};
		},
		//初始化Id
		created() {
			this.couponData.id = this.$route.query.Id
			this.ConfigId = this.$route.query.Id
			if (this.ConfigId > 0) {
				this.startData()
			}

		},
		methods: {
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			handleInputnumber(e) {
				e.target.value = e.target.value.match(/\d+(\.\d{0,2})?/) ? e.target.value.match(/\d+(\.\d{0,2})?/)[0] : ''
			},
			numberCheck(e) {
				let boottom = new RegExp("^[1-9][0-9]*$").test(e.target.value)
				if (!boottom) {
					e.target.value = ''
				}
			},

			async startData() {
				this.Wholoading = true
				try {
					let data = {
						Id: this.ConfigId
					}
					let result = await getScoreconfigInfo(data)
					if (result.IsSuccess) {
						this.ruleForm.GivingType = result.Result.GivingType
						if (result.Result.GivingType == 1) {
							this.ruleForm.ProductPay = result.Result.EachFull
							this.ruleForm.ProductSend = result.Result.Point
						} else {
							this.ruleForm.OrderPay = result.Result.EachFull
							this.ruleForm.OrderSend = result.Result.Point
						}
						if (result.Result.IsAllProduct == false) {
							this.ruleForm.IsAllProduct = 0
							this.ruleForm.ActivityCouponProductList = result.Result.PointGivingConfigInfoProductList
							let dataStart = this.page.size * (this.page.current - 1);
							let dataEnd = this.page.size * this.page.current;
							this.showFinaproList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
							this.page.total = this.ruleForm.ActivityCouponProductList.length;
							console.log('	this.ruleForm.ActivityCouponProductList', this.ruleForm.ActivityCouponProductList)

						} else {
							this.ruleForm.IsAllProduct = 1
						}
						console.log('	this.ruleForm.ActivityCouponProductList')
					}
					console.log(result, '详细信息')
				} catch (err) {

				} finally {
					this.Wholoading = false
				}
			},
			//保存信息
			SaveScore(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.sureLoading = true
						try {

							let ProductIdList = []
							if (this.ruleForm.ActivityCouponProductList.length) {
								ProductIdList = this.ruleForm.ActivityCouponProductList.map(item => {
									return item.Id
								})
							}
							let data = {
								Id: this.ConfigId,
								GivingType: this.ruleForm.GivingType,
								IsAllProduct: this.ruleForm.IsAllProduct,

							}
							if (this.ruleForm.GivingType == 0) {
								data.EachFull = this.ruleForm.OrderPay
								data.Point = this.ruleForm.OrderSend
							} else {
								data.EachFull = this.ruleForm.ProductPay
								data.Point = this.ruleForm.ProductSend
							}
							if (this.ruleForm.IsAllProduct == 0) {
								data.ProductIdList = ProductIdList
							}
							let result = await EditScoreconfig(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.$router.push({
									path: '/Customer/pointsManager',
								})

							}
						} catch (err) {

						} finally {
							this.sureLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//返回列表
			BackTolist() {
				this.$router.push({
					path: '/Customer/pointsManager',
				})
			},
			handleSelectProDialog() {
				this.selectProDialog = true
			},
			// 删除指定商品
			deleteSortPro(record) {
				this.$confirm('是否将该商品删除？', '删除提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.filter(item => {
						return record.Id != item.Id
					})
					let dataStart = this.page.size * (this.page.current - 1);
					let dataEnd = this.page.size * this.page.current;
					this.showFinaproList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
					// this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
					this.page.total = this.ruleForm.ActivityCouponProductList.length;

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {


				})
				// this.ruleForm.ActivityCouponProductList.splice(index, 1)
				// this.ProductIdList.splice(index, 1)
			},
			//获取选择商品的列表
			getSelectList(data) {
				this.ProductIdList = []
				this.ruleForm.ActivityCouponProductList = data
				console.log(this.ruleForm.ActivityCouponProductList, '确定之后的数据')
				let dataStart = this.page.size * (this.page.current - 1);
				let dataEnd = this.page.size * this.page.current;
				console.log(dataStart, dataEnd, '分页的隔断')
				this.showFinaproList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				// this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				this.page.total = this.ruleForm.ActivityCouponProductList.length;
				this.selectProDialog = false;
				// data.forEach(t => {
				// 	this.ProductIdList.push(t.Id)
				// });
				// console.log(999, this.ProductIdList)
			},
			handleSizeChange(val) {
				this.page.size = val;
				let dataStart = this.page.size * (this.page.current - 1);
				let dataEnd = this.page.size * this.page.current;
				this.showFinaproList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				// this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				this.page.total = this.ruleForm.ActivityCouponProductList.length;

			},
			handleCurrentChange(val) {
				this.page.current = val;
				let dataStart = this.page.size * (this.page.current - 1);
				let dataEnd = this.page.size * this.page.current;
				this.showFinaproList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				// this.ruleForm.ActivityCouponProductList = this.ruleForm.ActivityCouponProductList.slice(dataStart, dataEnd);
				this.page.total = this.ruleForm.ActivityCouponProductList.length;
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},



		},

	}
</script>

<style lang="less">
	.pointerResults {
		.nameStyle {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}


		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.input-with-select .el-input-group__prepend {
			padding: 0 8px;
		}
	}
</style>
